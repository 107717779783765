import type { ResourceScope } from '@obvio/server'

export const RESOURCE_PREFIXES: Partial<Record<ResourceScope, string>> = {
  page: '/',
  article: '/blog/',
}

export const PATHS = [
  { href: '/#magia-miejsca', title: 'Magia Miejsca' },
  { href: '/#o-nas', title: 'O Nas' },
  { href: '/#w-otoczeniu-natury', title: 'W otoczeniu natury' },
  { href: '/#kuchnia', title: 'Kuchnia' },
  { href: '/#sluby-w-plenerzy', title: 'Śluby w plenerze' },
  { href: '/#zapytaj', title: 'Zapytaj o termin' },
  { href: '/blog', title: 'Blog' },
  {
    href: 'https://www.instagram.com/palacmalawies_wedding/',
    title: 'Instagram',
  },
  { href: '/#map', title: 'Na mapie' },
]
