import { notDesktop, styled, Text, useMutation } from "@obvio/app";
import { FadeIn, RichText } from "@obvio/template";
import {
  CheckboxInput,
  Form,
  FormPage,
  Grid,
  NumberInput,
  Stack,
  Submit,
  SubmitState,
  TextArea,
  TextInput,
} from "@obvio/ui";
import { useCallback } from "react";

import type { ReactElement } from "react";

const Wrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
  h2 {
    font-weight: 400;
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

const SplitInputs = styled(Grid)`
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(theme) => theme.spacing.large};
`;

export function Contact(): ReactElement {
  const [requestContact] = useMutation("contact");

  const handleSubmit = useCallback(
    (data: {
      name: string;
      email: string;
      phone?: string;
      date: string;
      guests: number;
      message: string;
    }) => {
      void requestContact({
        name: data.name,
        email: data.email,
        phone: data.phone,
        date: data.date,
        guests: data.guests,
        message: data.message,
      });

      return SubmitState.OK;
    },
    [requestContact],
  );
  return (
    <Form contextOnly onSubmit={handleSubmit}>
      <FormPage>
        <Stack kind="vertical" align="center">
          <TextInput name="name" label="Imię i Nazwisko" />
          <TextInput name="email" label="Adres e-mail" type="email" />
          <SplitInputs>
            <TextInput
              name="date"
              label="Planowany termin przyjęcia:"
              optional
            />
            <NumberInput name="guests" label="Planowana liczba gości:" />
          </SplitInputs>
          <TextInput name="phone" label="Telefon" optional />
          <TextArea minRows={5} name="message" label="Treść wiadomości" />
          <CheckboxInput
            required
            name="rodo"
            label="Wyrażam zgodę na gromadzenie i przetwarzanie moich danych osobowych zgodnie z RODO i Polityką Prywatności w celu uzyskania informacji o otwarciu strony internetowej. Wyrażam zgodę na kontakt mailowy lub telefoniczny."
          />
          <Submit kind="ghost">Wyślij wiadomość</Submit>
        </Stack>
      </FormPage>
      <FormPage successPage>
        <Center>
          <FadeIn>
            <Text as="h3">Dziękujemy</Text>
          </FadeIn>
        </Center>
      </FormPage>
    </Form>
  );
}

const BottomStack = styled(Stack)`
  align-items: flex-end;
`;

type FormContactProps = {
  title: string;
  description: string;
  description2?: string;
  description3?: string;
};

export function FormContact({
  title,
  description,
  description2,
  description3,
}: FormContactProps): ReactElement {
  return (
    <Wrap templateColumns="1fr 1.25fr" gap="extraLarge">
      <Stack kind="vertical" spacing="large">
        <Text tag="h2" as="p">
          {title}
        </Text>
        <div style={{ flex: 1 }}>
          <RichText value={description} />
        </div>
        <BottomStack spacing="extraLarge">
          <div>
            <RichText value={description2 ?? ""} />
          </div>
          <div>
            <RichText value={description3 ?? ""} />
          </div>
        </BottomStack>
      </Stack>
      <Contact />
    </Wrap>
  );
}
