import { css } from "@obvio/app";
import { FadeIn } from "@obvio/template";
import { Section } from "@obvio/ui";
import { useMemo } from "react";

import type { ThemeContext } from "@obvio/app";
import type { SectionProps } from "@obvio/ui";
import type { AllowUndefinedForOptional } from "@obvio/utils";
import type { ReactElement } from "react";

type GolfSectionProps = Exclude<SectionProps, "context"> &
  AllowUndefinedForOptional<{
    name?: string;
    noFadeIn?: boolean;
    context?: "White" | "Green" | "formdark" | "green";
  }>;

export function CommonSection({
  noFadeIn,
  // @ts-expect-error
  context = "White",
  children,
  ...sectionProps
}: GolfSectionProps): ReactElement {
  const outerCss = useMemo(
    () => css`
      position: relative;
    `,
    [],
  );

  return (
    <Section
      context={context?.toLowerCase() as ThemeContext}
      outerCss={outerCss}
      {...sectionProps}
      id={sectionProps.name}
    >
      {noFadeIn ? children : <FadeIn>{children as ReactElement}</FadeIn>}
    </Section>
  );
}
