import { mobile, notDesktop, notMobile, styled, useRemToPx } from "@obvio/app";
import { SvgArrow2 } from "@obvio/svg";
import { CarouselKeen } from "@obvio/ui";
import { useMemo } from "react";

import { ButtonWithIcon } from "./ButtonWithIcon";
import { CommonSection } from "./CommonSection";

import type { CarouselKeenProps } from "@obvio/ui";
import type { KeenSliderPlugin } from "keen-slider/react";
import type { ReactElement } from "react";

export const CarouselDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(theme) => theme.stroke};
`;

export const CarouselImage = styled.div`
  width: 100%;
  height: 33.75rem;
  position: relative;

  @media ${mobile} {
    height: 9rem;
  }
`;

export const CarouselSection = styled(CommonSection)``;

const innerPlugins: Record<
  "imgAutoHeight" | "autoWidth",
  (...args: any[]) => KeenSliderPlugin
> = {
  autoWidth:
    (widths: number[]) =>
      ({ slides, update }) => {
        slides.forEach((slide, index) => {
          const width = widths[index];
          // eslint-disable-next-line no-param-reassign
          slide.style.minWidth = `${width}px`;

          // eslint-disable-next-line no-param-reassign
          slide.style.maxWidth = `${width}px`;
        });

        update();
      },
  imgAutoHeight:
    () =>
      // eslint-disable-next-line unicorn/consistent-function-scoping
      ({ slides, on }) => {
        function setImgHeights() {
          const heights = slides?.map((slide) => {
            const [imgWrap] = slide.children[0].children;
            return imgWrap.clientHeight;
          });
          const minHeight = Math.min(...heights);

          slides.forEach((slide) => {
            const [imgWrap] = slide.children[0].children;
            (imgWrap as HTMLDivElement).style.height = `${minHeight}px`;
          });
        }

        on("created", setImgHeights);
        on("optionsChanged", setImgHeights);
      },
};

type CarouselBaseProps<DataType> = {
  data: DataType[];
  options?: CarouselKeenProps<DataType>["options"];
  enhance?: CarouselKeenProps<DataType>["enhance"];
  plugins?: KeenSliderPlugin[];
  render(data: DataType): ReactElement;
};

const RotatedArrow = styled(SvgArrow2)`
  transform: rotate(180deg);
`;

const IconLeft = styled(ButtonWithIcon)`
  position: absolute;
  left: 0%;
  top: 100%;
  * {
    background: transparent !important;
  }
  @media ${notDesktop} {
    left: 0;
  }
`;

const IconRight = styled(ButtonWithIcon)`
  position: absolute;
  right: 0%;
  top: 100%;
  * {
    background: transparent !important;
  }
  @media ${notDesktop} {
    right: 0;
  }
`;

const defaultOptions = {};

export function CarouselBase<DataType>({
  data,
  options = defaultOptions,
  plugins,
  enhance,
  render,
}: CarouselBaseProps<DataType>): ReactElement {
  const remToPx = useRemToPx();

  const modifiedData = useMemo(() => {
    if (data.length === 2) {
      return [...data, data[0]];
    }
    return data;
  }, [data]);

  return (
    <CarouselKeen
      data={modifiedData}
      plugins={plugins}
      render={render}
      hideArrows
      enhance={
        enhance ??
        (({ children, next, previous }) => (
          <div style={{ position: "relative" }}>
            <IconLeft icon={SvgArrow2} onClick={previous}>
              PREV
            </IconLeft>
            {children}
            <IconRight icon={RotatedArrow} onClick={next} reversed>
              NEXT
            </IconRight>
          </div>
        ))
      }
      options={{
        defaultAnimation: {
          duration: 500,
        },
        loop: true,
        drag: false,
        breakpoints: {
          [mobile]: {
            slides: {
              perView: 1,
            },
          },
          [notMobile]: {
            slides: {
              perView: "auto",
              spacing: remToPx(2),
            },
          },
        },
        ...options,
      }}
    />
  );
}

CarouselBase.plugins = innerPlugins;
