import { styled, Text } from "@obvio/app";
import { Button, Stack } from "@obvio/ui";
import { motion } from "framer-motion";
import { useCallback } from "react";

import type { Svg } from "@obvio/svg";
import type { ButtonProps } from "@obvio/ui";
import type { ReactElement } from "react";

const StyledButton = motion(styled(Button)`
  background: transparent;
  color: ${(theme) => theme.colors.primary};
  padding: 0;
  > div {
    align-items: center;
  }
`);

const IconBg = motion(styled.div`
  height: 2.3125rem;
  aspect-ratio: 1;
  border-radius: 9999px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(theme) => theme.colors.primary.dark};
  padding: 0.375rem;
`);

export function ButtonWithIcon({
  children,
  icon: Icon,
  reversed,
  ...btnProps
}: {
  children?: string;
  icon: Svg;
  reversed?: boolean | undefined;
} & ButtonProps): ReactElement {
  const IconElem = useCallback(
    () => (
      <IconBg
        variants={{
          initial: {
            x: 0,
          },
          hover: {
            x: 5 * (reversed ? 1 : -1),
          },
        }}
      >
        <Icon />
      </IconBg>
    ),
    [Icon, reversed],
  );

  const Content = useCallback(
    () => (children ? <Text>{children}</Text> : null),
    [children],
  );

  return (
    // @ts-expect-error
    <StyledButton {...btnProps} initial="initial" whileHover="hover">
      <Stack>
        {reversed ? (
          <>
            <Content />
            <IconElem />
          </>
        ) : (
          <>
            <IconElem />
            <Content />
          </>
        )}
      </Stack>
    </StyledButton>
  );
}
