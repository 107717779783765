import { Link, notDesktop, styled, Text } from "@obvio/app";
import { SvgLogo, SvgLogoLong } from "@obvio/svg";
import { useUi } from "@obvio/template";
import { Button, GridItem } from "@obvio/ui";
import { useScroll } from "framer-motion";
import { useEffect, useState } from "react";

import { useFormContactModal } from "./Modals/FormContactModal";
import {
  NavElement,
  NavContent,
  ButtonWrap,
  MenuSvg,
  NavButton,
} from "./Nav/components";

import type { ReactElement } from "react";

const LogoLong = styled(SvgLogoLong)`
  @media ${notDesktop} {
    display: none;
  }
`;

const Logo = styled(SvgLogo)`
  display: none;
  @media ${notDesktop} {
    display: block;
  }
`;

const TextButton = styled(Button)`
  width: auto;
  padding: 0;
`;

export function Nav(): ReactElement {
  const { show } = useFormContactModal();
  const [scrolled, setScrolled] = useState(false);
  const [, dispatch] = useUi("menuPanelOpen");
  const { scrollY } = useScroll();

  useEffect(() => {
    scrollY.on("change", (v) => {
      setScrolled(v > 0);
    });

    return () => scrollY.destroy();
  });

  return (
    <NavElement $scrolled={scrolled}>
      <NavContent templateColumns="auto 1fr 20%">
        <TextButton kind="transparent" onClick={() => show()}>
          <Text tag="span">ZAPYTAJ O TERMIN</Text>
        </TextButton>
        <GridItem>
          {scrolled ? null : (
            <Link href="/">
              <LogoLong />
              <Logo />
            </Link>
          )}
        </GridItem>
        <ButtonWrap>
          <NavButton
            onClick={() => dispatch("OPEN_MENU_PANEL")}
            kind={["icon", "transparent"]}
          >
            <MenuSvg />
          </NavButton>
        </ButtonWrap>
      </NavContent>
    </NavElement>
  );
}
