import { Image, mobile, notMobile, styled, useRemToPx } from "@obvio/app";
import { Stack } from "@obvio/ui";
import { useCallback } from "react";

import { CarouselBase } from "./CarouselBase";

import type { ImageAsset } from "@obvio/app";
import type { ReactElement } from "react";

const ImageWrap = styled(Stack)`
  width: 100%;
  aspect-ratio: 1.75;
  color: ${(theme) => theme.colors.secondary};
  text-align: left;
  overflow: hidden;
`;

type ImageCompProps = {
  image: ImageAsset;
};

export function ImageComp({ image }: ImageCompProps): ReactElement {
  return (
    <ImageWrap>
      <Image img={image} sizes="100vw" />
    </ImageWrap>
  );
}

type ImagesCarouselProps = {
  images: ImageAsset[];
};

const defaultImages: ImageAsset[] = [];

// TODO: If required check if it's natively possible to move non-active slides more to the edges if preView: 2 + origin: "center". Maybe plugins is needed but it's bonkers
export function ImagesCarousel({
  images = defaultImages,
}: ImagesCarouselProps): ReactElement {
  const remToPx = useRemToPx();

  const MemoizedImage = useCallback(
    (image: ImageAsset) => <ImageComp image={image} />,
    [],
  );

  return (
    <CarouselBase
      data={images}
      render={MemoizedImage}
      options={{
        defaultAnimation: {
          duration: 500,
        },
        autoSwitchTimeout: 2500,

        breakpoints: {
          [notMobile]: {
            loop: true,
            slides: {
              perView: 1,
              spacing: remToPx(1),
            },
          },
          [mobile]: {
            loop: true,
            slides: {
              perView: 1,
            },
          },
        },
      }}
    />
  );
}
