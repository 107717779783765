import { mobile, notDesktop, styled } from "@obvio/app";
import { SvgMenu } from "@obvio/svg";
import { Button, Grid } from "@obvio/ui";

export const NavElement = styled.nav<{ $scrolled?: boolean }>`
  min-height: var(--nav-height);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  margin: 0 auto;
  background-color: ${(_, { $scrolled }) =>
    $scrolled ? "white" : "transparent"};
  border-bottom: ${(theme, { $scrolled }) =>
    $scrolled ? `1px solid ${theme.colors.primary}` : "transparent"};
  @media ${mobile} {
    max-width: 100%;
  }
  * {
    color: ${(theme, { $scrolled }) =>
    $scrolled ? theme.colors.primary : "white"} !important;
  }
`;

export const NavContent = styled(Grid)`
  height: var(--nav-height);
  align-items: center;
  margin: 0 auto;
  width: 82rem;
  max-width: calc(100% - 7.5rem);
  @media ${notDesktop} {
    max-width: calc(100vw - 4rem);
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  a {
    color: white;
  }
`;

export const MenuSvg = styled(SvgMenu)`
  svg {
    color: white;
  }
`;

export const NavButton = styled(Button)``;
