import { Context, notDesktop, styled, Text, useParamModal } from "@obvio/app";
import { RichText } from "@obvio/template";
import { Grid, GridItem, Modal, Stack } from "@obvio/ui";

import { Contact } from "../Renderer/Renderers/Form";

import type { ReactElement } from "react";

const Wrap = styled(Grid)`
  width: 80%;
  margin: auto;
  @media ${notDesktop} {
    grid-template-columns: 1fr;
    width: 100%;
  }
  h2 {
    line-height: 100%;
    font-weight: 400;
  }
`;

export function FormContactModal(): ReactElement {
  const { open, hide } = useParamModal(FormContactModal.PARAM);

  return (
    <Context name="green">
      <Modal open={open} onClose={hide}>
        <Wrap templateColumns="1fr 1fr" gap="extraLarge">
          <GridItem>
            <Stack kind="vertical" spacing="large">
              <Text tag="h2" as="p">
                PAŁAC MAŁA WIEŚ
              </Text>
              <div style={{ flex: 1 }}>
                <RichText
                  value={`
#Mała Wieś 40\n
#05-622 Belsk Duży\n
#20°28′E, 55°37′N\n\n\n\n
#+ 48 48 332 26 70
#biuro@palacmalawies.pl
						`}
                />
              </div>
            </Stack>
          </GridItem>
          <GridItem>
            <Contact />
          </GridItem>
        </Wrap>
      </Modal>
    </Context>
  );
}

FormContactModal.PARAM = "contact";

export const useFormContactModal: () => ReturnType<typeof useParamModal> = () =>
  useParamModal(FormContactModal.PARAM);
