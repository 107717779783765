import { styled, notDesktop, Text } from "@obvio/app";
import { FadeIn, Map, MapSection } from "@obvio/template";

import { MAP_MARKERS, MAP_STYLE } from "@/constants/map";

import type { ReactElement } from "react";

export const MapSectionSpacing = styled(MapSection)`
  margin: 0;
  height: 60vh;
  > div {
    height: 100%;
  }
  @media ${notDesktop} {
    height: 30rem;
  }
  background: ${(theme) => theme.colors.primary};
`;

const Marker = styled.div<{ $active: boolean }>`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 99999px;
  background: white;
  z-index: ${(_, { $active }) => ($active ? 3 : 2)};
  position: relative;
  cursor: pointer;
  font-famly: ${(theme) => theme.font} !important;
  span {
    font-size: 1rem;
  }
`;

const MarkerBody = styled.div`
  padding: ${(theme) => theme.spacing.small};
  color: white;
  position: absolute;
  bottom: 100%;
  transform: translateX(-50%);
  width: auto;
  span {
    color: white;
    white-space: nowrap;
  }
`;

export function GolfMap(): ReactElement {
  return (
    <FadeIn>
      <Map
        defaultZoom={10}
        defaultCenter={{
          lat: 51.924_619_106_324_26,
          lng: 20.790_213_814_401_348,
        }}
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API ?? ""}
        markers={MAP_MARKERS}
        marker={(data) => (
          <Marker onClick={() => data.onClick?.()} $active>
            <MarkerBody>
              <Text tag="span">{data.activeMarker?.title}</Text>
            </MarkerBody>
          </Marker>
        )}
        options={{
          styles: MAP_STYLE,
          disableDefaultUI: true,
        }}
      />
    </FadeIn>
  );
}
