import { Context, Link, notDesktop, styled, Text } from "@obvio/app";
import { SvgCloseMenu, SvgSzklarniaText } from "@obvio/svg";
import { useUi } from "@obvio/template";
import { Button, Drawer, Grid, Stack } from "@obvio/ui";
import { DrawerContent } from "@obvio/ui/Drawer/components";
import { motion } from "framer-motion";
import { useCallback } from "react";

import { PATHS } from "@/constants";

import type { ReactElement } from "react";

const DrawerPanel = styled(Drawer)`
  section > div {
    width: 100vw;
    max-width: 65rem;
    > div > div {
      background: ${(theme) => theme.colors.primary};
    }
  }
  min-height: 100svh;
  @media ${notDesktop} {
    section {
      inset: 0;
    }
    section > div {
      max-width: 100vw;
      width: 100vw;
    }
  }
`;

const Content = styled(DrawerContent)`
  padding: ${(theme) => theme.spacing.large};
  padding-top: 15vh;
`;

const MotionLink = motion(styled(Link)`
  ${(theme) => theme.typography.h2?.css}
  font-weight: 400;
  @media ${notDesktop} {
    font-size: 2rem !important;
  }
`);

const ItemsGrid = motion(styled(Grid)`
  grid-auto-flow: column;
  @media ${notDesktop} {
    display: flex;
    flex-direction: column;
  }
`);

const ContentStack = styled(Stack)`
  height: 100%;
`;

const SzklarniaText = styled(SvgSzklarniaText)`
  margin-top: auto !important;
  transform: translateX(-10%);
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: ${(theme) => theme.spacing.small};
  right: ${(theme) => theme.spacing.small};
  color: white;
  margin: 0 !important;
`;

const TopText = styled(Text)`
  position: absolute;
  top: ${(theme) => theme.spacing.small};
  left: ${(theme) => theme.spacing.large};
  color: white;
  margin: 0 !important;
  height: 3.5rem;
  text-transform: uppercase;
  ${(theme) => theme.flexCenter}
`;

const item = {
  animate: { opacity: 1, y: 0 },
  initial: { opacity: 0, y: -10 },
};

export function MobilePanel(): ReactElement | null {
  const [{ menuPanelOpen }, dispatch] = useUi("menuPanelOpen");

  const close = useCallback(() => dispatch("CLOSE_ALL_PANELS"), [dispatch]);

  if (!menuPanelOpen) {
    return null;
  }

  return (
    <Context name="menu">
      <DrawerPanel handleClickOutside={close}>
        <Content>
          <ContentStack kind="vertical" spacing="extraSmall">
            <ItemsGrid
              templateColumns="1fr 1fr"
              templateRows="repeat(6, auto)"
              gap="small"
              initial="initial"
              animate="animate"
              transition={{
                when: "beforeChildren",
                staggerChildren: 0.15,
                delayChildren: 0.2,
              }}
            >
              {PATHS.map(({ href, title }) => (
                <MotionLink
                  key={title}
                  href={href}
                  variants={item}
                  onClick={close}
                >
                  {title}
                </MotionLink>
              ))}
            </ItemsGrid>
            <SzklarniaText height="unset" width="125%" />
            <TopText tag="span">szklarnia – Pałac Mała Wieś</TopText>
            <CloseButton kind={["icon", "transparent"]} onClick={close}>
              <SvgCloseMenu />
            </CloseButton>
          </ContentStack>
        </Content>
      </DrawerPanel>
    </Context>
  );
}
