import { Context, Link, notDesktop, styled, Text } from "@obvio/app";
import { SvgSzklarniaText } from "@obvio/svg";
import { Stack } from "@obvio/ui";

import { CommonSection } from "./CommonSection";
import { GolfMap, MapSectionSpacing } from "./Renderer/Renderers/Map";

import type { ReactElement } from "react";

const FooterWrap = styled.footer`
  z-index: 1;
  position: relative;
  background-color: ${(theme) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: ${(theme) => theme.spacing.small};
  > div > svg {
    max-width: 100%;
    width: 100%;
  }

  * {
    color: white;
  }
  @media ${notDesktop} {
    height: auto;
  }
`;

const Content = styled(Stack)`
  max-width: calc(100% - 7.5rem);
  padding: 0 0 5rem 0;
  margin: 0 auto;
  width: 82rem;
  flex: 1;
  p {
    font-family: ${(theme) => theme.secondaryFont};
  }
  @media ${notDesktop} {
    max-width: calc(100% - 5rem);
  }
  > div {
    overflow: hidden;
  }
`;

const LinksStackDesktop = styled(Stack)`
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  > div:nth-child(odd) {
    padding: 1rem;
    flex: 1;
  }
  @media ${notDesktop} {
    display: none;
  }
`;

const LinksStackMobile = styled(Stack)`
  display: none;
  @media ${notDesktop} {
    display: flex;
  }
  text-align: center;
  margin-top: 2rem;
`;

const BottomPanel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0 !important;
  @media ${notDesktop} {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
`;

const DividerVertical = styled.div`
  width: 1px;
  height: 100%;
  background-color: white;
`;

const BottomText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Heading = styled(Text)`
  font-size: 3.875rem;
  text-align: center;
  font-family: ${(theme) => theme.font};
  font-stretch: condensed;
  font-weight: 300;
  text-transform: uppercase;
`;

const MapLinkWrap = styled.div`
  margin-top: ${(theme) => theme.spacing.extraLarge};
  text-align: center;
  a {
    text-decoration: underline;
  }
`;

const links = [
  { title: "Magia Miejsca", href: "/#magia-miejsca" },
  { title: "O Nas", href: "/#o-nas" },
  { title: "W otoczeniu natury", href: "/#w-otoczeniu-natury" },
  { title: "Kuchnia", href: "/#kuchnia" },
  { title: "Śluby w plenerze", href: "/#sluby-w-plenerze" },
  { title: "Zapytaj o termin", href: "/#zapytaj" },
  { title: "Blog", href: "/blog" },
  {
    title: "Instagram",
    href: "https://www.instagram.com/palacmalawies_wedding/",
  },
  { title: "Regulamin", href: "/regulamin" },
  { title: "Polityka Prywatności", href: "/polityka-prywatnosci" },
];

const mobileLinks = [
  { title: "Menu", href: "/" },
  { title: "Zarezerwuj termin", href: "/#zapytaj" },
  { title: "Magia Miejsca", href: "/#magia-miejsca" },
  { title: "Wzruszające śluby w plenerze", href: "/#sluby-w-plenerze" },
  { title: "Miejsce otoczone naturą", href: "/#w-otoczeniu-natury" },
  { title: "Regulamin", href: "/regulamin" },
  { title: "Polityka Prywatności", href: "/polityka-prywatnosci" },
];

const linksDuos = links.reduce<(typeof links)[number][][]>(
  (acc, v) => {
    if (acc[acc.length - 1].length === 2) {
      acc.push([v]);
    } else {
      acc[acc.length - 1].push(v);
    }
    return acc;
  },
  [[]],
);

export function Footer(): ReactElement {
  return (
    <Context name="green">
      <CommonSection context="green">
        <Heading tag="h3">
          Rustykalne wesele | Boho Szklarnia | Stodoła | Boho wesele blisko
          Warszawy | Wesele Warszawa | Ślub plenerowy Warszawa | wesele loftówe
        </Heading>
        <MapLinkWrap>
          <Link href="https://www.google.com/maps/dir//Pa%C5%82ac+Ma%C5%82a+Wie%C5%9B,+Ma%C5%82a+Wie%C5%9B+40,+05-622+Ma%C5%82a+Wie%C5%9B/@51.8246143,20.7876381,17z/data=!4m17!1m7!3m6!1s0x47191762b523b14f:0xae6beba277372eaf!2zUGHFgmFjIE1hxYJhIFdpZcWb!8m2!3d51.824611!4d20.790213!16s%2Fg%2F11cs69l78s!4m8!1m0!1m5!1m1!1s0x47191762b523b14f:0xae6beba277372eaf!2m2!1d20.790213!2d51.824611!3e2?entry=ttu">
            ZOBACZ JAK DOJECHAĆ
          </Link>
        </MapLinkWrap>
      </CommonSection>
      <MapSectionSpacing>
        <GolfMap />
      </MapSectionSpacing>
      <FooterWrap>
        <Content kind="vertical" spacing="medium">
          <SvgSzklarniaText />
          <BottomText>
            <Text>PAŁAC MAŁA WIEŚ</Text>
            <Text>#SLOWWEDDING</Text>
          </BottomText>
          <Divider />
          <BottomPanel>
            <LinksStackDesktop divider={DividerVertical}>
              {linksDuos.map(([first, second]) => (
                <div key={first.title}>
                  <Stack kind="vertical">
                    <Link key={first.title} href={first.href}>
                      {first.title}
                    </Link>

                    <Link key={second.title} href={second.href}>
                      {second.title}
                    </Link>
                  </Stack>
                </div>
              ))}
            </LinksStackDesktop>
            <LinksStackMobile kind="vertical" spacing="medium">
              {mobileLinks.map((link) => (
                <Link key={link.title} href={link.href}>
                  {link.title}
                </Link>
              ))}
            </LinksStackMobile>
          </BottomPanel>
        </Content>
      </FooterWrap>
    </Context>
  );
}
