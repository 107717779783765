import { Context, css, Global, styled, useIsClient } from "@obvio/app";
import { CookiesConsentBasicModal } from "@obvio/template";
import { ObvioFooter } from "@obvio/ui";

import { Footer } from "./Footer";
import { MobilePanel } from "./MobilePanel";
import { FormContactModal } from "./Modals/FormContactModal";
import { GalleryModal, GalleryModalProvider } from "./Modals/GalleryModal";
import { Nav } from "./Nav";

import type { AllowUndefinedForOptional } from "@obvio/utils";
import type { ReactElement } from "react";

const StyledObvioFooter = styled(ObvioFooter)`
  margin: 0 auto;

  > a {
    color: white;
  }
`;

const globalStyle = css`
  body {
    background-color: ${(theme) => theme.background};
  }
  html {
    scroll-behavior: smooth;
  }
  ${(theme) => theme.global}
`;

const StyledCookiesModal = styled(CookiesConsentBasicModal)`
  p,
  a,
  button:first-of-type {
    color: ${(theme) => theme.colors.primary.dark};
    border-color: ${(theme) => theme.colors.primary.dark};
  }
`;

type LayoutProps = AllowUndefinedForOptional<{
  children: ReactElement | ReactElement[];
}>;

export function Layout({ children }: LayoutProps): ReactElement {
  const isClient = useIsClient();

  return (
    <GalleryModalProvider>
      <Nav />
      <main>{children}</main>
      <Footer />
      <StyledObvioFooter />
      <FormContactModal />
      <MobilePanel />
      <Global styles={globalStyle} />
      <GalleryModal />
      {isClient ? (
        <Context name="formdark">
          <StyledCookiesModal />
        </Context>
      ) : null}
    </GalleryModalProvider>
  );
}
